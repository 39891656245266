/** @jsx jsx */
import React from 'react'
import { jsx, Box } from 'theme-ui'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'

import Header from '../components/Header'

const Posts = ({ data, pageContext }) => {
  const heroImage = data.contentfulAsset
  const posts = data.allContentfulPost.edges
  const courses = data.allContentfulCourse.edges

  let ogImage

  try {
    ogImage = posts[0].node.heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO title={'Blog & Safety Courses | Safe Beach Day'} image={ogImage} />
      <Header
        hideSearch
        headerText={'Safety tips for the beach'}
        image={heroImage?.gatsbyImageData}
        imageCred={heroImage?.description}
      />
      <Container mw padded>
        <Box>
          <h2>Beach Safety Courses</h2>
          <CardList>
            {courses.map(({ node: course }) => (
              <Card
                key={course.id}
                {...course}
                basePath={'course'}
                subtitle={`${course.posts.length} ${
                  course.posts.length === 1 ? 'lessons' : 'lessons'
                }`}
              />
            ))}
          </CardList>
        </Box>
      </Container>
      <Container mw padded>
        <Box>
          <h2>Articles</h2>
          <CardList>
            {posts.map(({ node: post }) => (
              <Card key={post.id} {...post} basePath={'post'} />
            ))}
          </CardList>
        </Box>
      </Container>
      <Pagination context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query Blog {
    contentfulAsset(title: { eq: "SafetyTips" }) {
      title
      description
      gatsbyImageData(layout: CONSTRAINED, width: 2400)
      file {
        url
      }
    }

    allContentfulPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            gatsbyImageData(width: 1800)
            ogimg: resize(width: 1800) {
              src
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
    allContentfulCourse {
      edges {
        node {
          id
          slug
          title
          description {
            description
          }
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage: mainImage {
            title
            gatsbyImageData(width: 1800)
            ogimg: resize(width: 1800) {
              src
            }
          }
          posts {
            id
          }
        }
      }
    }
  }
`

export default Posts
